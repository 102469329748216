




































































































































































import { Component, Watch } from 'vue-property-decorator'
import { AnyObject, Authentication, AuthServiceType } from '@movecloser/front-core'

import { SelectItem } from '../../../dsl/atoms/Select'
import { SelenaForm } from './SelenaFormWrapper'
import { Inject } from '../../../support'
import { UserModel } from '../../../front/auth/shared'

@Component<IssueCertificate>({
  name: 'IssueCertificate',
  mounted () {
    // this.onUserChange()
  }
})
export class IssueCertificate extends SelenaForm {
  @Inject(AuthServiceType, false)
  private readonly authService?: Authentication<UserModel>

  public formData = {
    name: '',
    title: '',
    address: '',
    zipCode: '',
    country: '',
    city: '',
    type: '',
    installationType: '',
    systems: ['System WINS Flex'],
    windowCount: '',
    sealAmount: '',
    endDate: '',
    buyingPlace: '',
    certificationDate: '',
    files: [],
    // author: this.authService?.user?.getFullName() || 'xxxx'
    company: ''
  }

  public get defaultDate () {
    return new Date()
  }

  public get user (): UserModel | null {
    return this.$store.getters['profile/user']
  }

  public disabledDates (date: Date): boolean {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    return date > today
  }

  public get typeOptions (): SelectItem[] {
    const typeOptions = this.options.type || []
    return typeOptions.map((item: AnyObject) => {
      if (item && 'label' in item && 'value' in item) {
        return {
          label: String(this.$t(item.label as string)),
          value: item.value
        }
      } else {
        return {
          label: '',
          value: ''
        }
      }
    })
  }

  public get countriesOptions (): SelectItem[] {
    return [
      { label: 'Polska', value: 'PL' },
      { label: 'Deutschland', value: 'DE' },
      { label: 'Magyarország', value: 'FR' }
    ]
  }

  public get installationTypeOptions (): SelectItem[] {
    const typeOptions = this.options.installationType || []
    return typeOptions.map((item: AnyObject) => {
      if (item && 'label' in item && 'value' in item) {
        return {
          label: String(this.$t(item.label as string)),
          value: item.value
        }
      } else {
        return {
          label: '',
          value: ''
        }
      }
    })
  }

  @Watch('user')
  protected onUserChange (): void {
    // console.log('onUserChange')
    // this.formData = { ...this.formData, author: this.user?.getFullName() ?? '' }
  }
}

export default IssueCertificate
